import React from "react"
import { loadStripe } from "@stripe/stripe-js"
import { Card, CardDeck, Button } from "react-bootstrap"
import { FaShoppingCart } from "react-icons/fa"


const stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_API}`)

const redirectToCheckout = async (level, event) => {
  event.preventDefault()
  const stripe = await stripePromise
  const { error } = await stripe.redirectToCheckout({
    lineItems: [{price: level, quantity: 1}],
    mode: 'payment',
    successUrl: `${process.env.GATSBY_AUTH0_HOME}/sponsor_success`,
    cancelUrl: `${process.env.GATSBY_AUTH0_HOME}/sponsor_canceled`
  })

  if (error) {
    console.warn("Error:", error)
  }
}

const SponsorLinks = () => {

  return (

        <>
        <CardDeck>
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>Gold</Card.Title>
                <Card.Text>
                  $2,000
                </Card.Text>
                <Button variant="primary" onClick={(event) => redirectToCheckout(`${process.env.GATSBY_STRIPE_GOLD}`, event)}><FaShoppingCart  style={{marginRight: "3px"}}/>Sponsor Now</Button>
              </Card.Body>
            </Card> 
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>Silver</Card.Title>
                <Card.Text>
                  $1,000
                </Card.Text>
                <Button variant="primary" onClick={(event) => redirectToCheckout(`${process.env.GATSBY_STRIPE_SILVER}`, event)}><FaShoppingCart  style={{marginRight: "3px"}}/>Sponsor Now</Button>
              </Card.Body>
            </Card> 
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>Bronze</Card.Title>
                <Card.Text>
                  $500
                </Card.Text>
                <Button variant="primary" onClick={(event) => redirectToCheckout(`${process.env.GATSBY_STRIPE_BRONZE}`, event)}><FaShoppingCart  style={{marginRight: "3px"}}/>Sponsor Now</Button>
              </Card.Body>
            </Card> 
          </CardDeck>
        </>
  )
}

export default SponsorLinks